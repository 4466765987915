<template>
  <div>
    <div class="disbox">
      <el-row>
        <el-col class="asd">
          <div class="bg">
            <div class="rectitle">
              {{ drugData.name }}
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="3">
          <div class="btn" @click="currentIndex = 0" :class="{ actived: 0 === currentIndex }">
            首页
          </div>
          <div class="btn" @click="currentIndex = 1" :class="{ actived: 1 === currentIndex }"
            v-if="insData.length != 0">
            相关说明书
          </div>
        </el-col>
        <el-col :span="21" style="position: relative;">
          <div class="dcontainer">
            <div class="shouye" v-if="currentIndex == 0" style="padding-left: 20px;">
              <div class="guide" ref="zhinan">
                <el-row>
                  <el-col :span="24" class="guideTitle">
                    <div>
                      <img src="~assets/dian.png" alt="" />
                    </div>
                    <div>
                      <div class="zn">基本信息</div>
                    </div>
                  </el-col>
                  <div class="infoBar" v-for="(item, index) in drugData.desc" :key="index">
                    <div class="gray">{{ getTitle(item.title) }}</div>
                    <div class="wenan">{{ item.content }}</div>
                  </div>
                </el-row>
              </div>
            </div>
            <div v-if="currentIndex == 1" class="xq" ref="xq">
              <el-row class="row" style="padding-top: 15px">
                <el-col :span="24" v-for="project in insData" :key="project.id">
                  <div class="insBar" @click="jumpToIns(project.id)">
                    <div class="insTitleBar">
                      <img src="~assets/dian.png" alt="">
                      <div class="insTitle">{{ project.instructionName }}</div>
                    </div>
                    <div class="company">生产公司：{{ project.productionCompany }}</div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <bottom-bar v-if="currentIndex === 0" class="bottom"></bottom-bar>
  </div>
</template>

<script>
import BottomBar from 'components/content/bottombar/BottomBar'
import {
  listDrugInstructionName,
  queryDrugInfo
} from 'network/getData1'

export default {
  name: 'DrugDeatil',
  components: {
    BottomBar
  },
  data() {
    return {
      drugData: {},
      currentIndex: 0,
      insData: [],
    }
  },
  async mounted() {
    let res = await queryDrugInfo(this.$route.query.id)
    this.drugData = res.data.data
    let res1 = await listDrugInstructionName(100, 1, this.drugData.name)
    if (res1.data.data.length != 0) {
      this.insData = res1.data.data
    }
  },
  methods: {
    getTitle(str) {
      let str1 = str.slice(1, -1)
      return str1 + '：'
    },
    jumpToIns(e) {
      this.$router.push({ path: '/drugInsInfo', query: { id: e } })
    },
  },
}

</script>

<style scoped>
.disbox {
  /* position: absolute; */
  width: 1200px;
  /* height: 631px; */
  /* left: 120px; */
  /* margin-left: 120px; */
  top: 130px;
  margin: 0 auto;
}

.title {
  /* position: relative; */
}

.rectitle {
  font-size: 36px;
  line-height: 54px;
  /* width: 300px; */
  height: 100px;
  text-align: center;
  position: relative;
  top: -35px;
  /* float: left; */
  /* margin-left: 50%;
    transform: translateX(-50%); */
}

.bg {
  position: absolute;
  /* width: inherit; */
  height: 14px;
  /* margin: 0 -78px; */
  margin-top: 50px;
  padding: 0 50px;
  /* opacity: 0.4; */
  transform: translateX(-50%);
  left: 50%;
  background-color: rgba(246, 166, 9, 0.4);
}

.llist {
  position: absolute;
  width: 112px;
  height: 230px;
  left: 20px;
  top: 123px;
}

.dcontainer {
  position: relative;
  width: 1008px;
  /* height: 452px;
    left: 192px;
    top: 122px; */
  background: #FFFFFF;
}

/* .yyvideo{
    position: absolute;
    margin-top:-120px;
  } */
.now {
  position: relative;
}

/* .el-col{
    width: 222px;
  } */
.span {
  width: 222px;
}

.el-col-offset-1 {
  margin-left: 2%;
  margin-bottom: 1%;
}

/* .detail .el-col-6 :hover,
.detail .el-col-24 :hover,
.xq .el-col-6 :hover,
.xq .el-col-24 :hover {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.detail .now :hover,
.xq .insTitle :hover {
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(0, 0, 0, 0);
} */

.row {
  position: relative;
  /* top: 50px; */
}

.bottom {
  margin-top: 160px;
}

.guideTitle {
  color: #262626;
  display: flex;
  align-items: center;
  margin: 18px 0px;
}

.guideTitle .zn {
  margin-left: 10px;
  font-size: 20px;
}

.infoBar {
  width: 100%;
  height: auto;
  margin-bottom: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

/* .guideTitle :hover{
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1);
  } */
.gray {
  white-space: nowrap;
  color: #8C8C8C;
}

.guide .el-row {
  margin-top: 8px;
  font-size: 16px;
  line-height: 24px;
}

.wenan {
  color: #262626;
}

.more {
  /* margin: 0 auto; */
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  /* position: absolute;
    width: 75px;
    height: 22px;
    left: 903px;
    top: 10px;
    z-index: 99; */
  color: #8C8C8C;
}

.more:hover {
  color: #000000;
}

.btn {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px 0;
  /* position: static; */
  /* width: 84px; */
  height: 30px;
  /* left: 0px;
  top: 50px; */
  background: #F5F5F5;
  border-radius: 4px;
  flex: none;
  justify-content: center;
  align-items: center;
  /* order: 1;
  flex-grow: 0; */
  margin: 20px 0px;
  cursor: pointer;
}

.actived {
  background: #4263EB;
  color: #FFFFFF;
}

.el-pagination {
  top: 50px;
  margin-top: 40px;
  position: relative;
}

.ql {
  width: 1038px;
}

.detail .el-col-24 {
  width: 1038px;
}

.detail {
  position: relative;
}

.detail .el-row {
  margin-top: 30px;
}

.dian {
  width: 8px;
  height: 8px;
}

.dian img {
  width: 100%;
  height: 100%;
}

.el-col-6 {
  width: 22%;
}

.more a {
  cursor: pointer;
}

.xq .el-col-24 :hover {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.xq .insBar :hover {
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(0, 0, 0, 0) !important;
}

.insBar {
  box-sizing: border-box;
  width: 100%;
  height: 88px;
  margin-bottom: 20px;
  padding-top: 10px;
  padding-bottom: 8px;
  padding-left: 20px;
  cursor: pointer;
}

.insTitleBar {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 18px;
}

.insTitle {
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  margin-left: 8px;
}

.company {
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #8c8c8c;
}
</style>